import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "q-py-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_PlantaoDuvidaForm = _resolveComponent("PlantaoDuvidaForm")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-mb-md" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_form, {
              onSubmit: _ctx.pesquisar,
              class: "row items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_select, {
                  dense: "",
                  label: "Cursos",
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = (val) => _ctx.getTurmas(val.id)),
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cursoSelected) = $event))
                  ],
                  class: "col-4",
                  "option-value": "id",
                  "option-label": "descricao",
                  modelValue: _ctx.cursoSelected,
                  options: _ctx.cursos
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_q_select, {
                  dense: "",
                  label: "Turmas",
                  class: "col-4",
                  "option-value": "id",
                  "option-label": "descricao",
                  modelValue: _ctx.turmaSelected,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.turmaSelected) = $event)),
                  options: _ctx.turmas
                }, null, 8, ["modelValue", "options"]),
                (_ctx.turmaSelected)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      class: "q-ml-sm",
                      padding: "sm",
                      type: "submit",
                      icon: "search",
                      color: "primary"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  class: "q-ml-sm",
                  padding: "sm",
                  color: "red",
                  icon: "undo",
                  to: "/diario-classe"
                })
              ]),
              _: 1
            }, 8, ["onSubmit"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_PlantaoDuvidaForm, {
        turmas: _ctx.turmas,
        disciplinas: _ctx.disciplinas,
        onUpdate: _ctx.list,
        ref: "modalForm"
      }, null, 8, ["turmas", "disciplinas", "onUpdate"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_table, {
          "row-key": "id",
          rows: _ctx.ocorrencias,
          columns: _ctx.columns,
          filter: _ctx.filter,
          loading: _ctx.loading,
          pagination: { rowsPerPage: 10 }
        }, {
          "body-cell-status": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (props.value)
                  ? (_openBlock(), _createBlock(_component_q_badge, {
                      key: 0,
                      color: "green",
                      label: "ATIVO"
                    }))
                  : (_openBlock(), _createBlock(_component_q_badge, {
                      key: 1,
                      color: "negative",
                      label: "INATIVO"
                    }))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-created_at": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-actions": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  flat: "",
                  dense: "",
                  color: "primary",
                  "icon-right": "edit",
                  disable: _ctx.loading,
                  onClick: ($event: any) => (_ctx.openModalForm(props.row))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Editar ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  flat: "",
                  dense: "",
                  color: "red",
                  "icon-right": "delete",
                  disable: _ctx.loading,
                  onClick: ($event: any) => (_ctx.confirmDelete(props.row.id, 'Plantão'))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Excluir ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  "no-caps": "",
                  flat: "",
                  dense: "",
                  color: "primary",
                  "icon-right": "link",
                  onClick: ($event: any) => (_ctx.copyLink(props.row))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" Copiar Link ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "filter", "loading"])
      ])
    ])
  ]))
}