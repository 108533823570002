const OcorrenciaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    style: "width: 100px",
    sortable: false,
  },
  {
    name: "id",
    align: "left",
    label: "ID",
    field: "id",
    sortable: true,
  }
];

export default OcorrenciaColumn;
